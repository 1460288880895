// import logo from '../../logo.svg';
import { useNavigate } from 'react-router-dom';
import '../App.css';


import React from "react";





const Home = ()=>{
    const navigateThere = useNavigate()


    return(


    <div >
        <div className="flex justify-center items-center ">
                <button className=" items-center flex
                mt-5 bg-blue-300 p-2 rounded-xl" 
                onClick={() => {
                    navigateThere("/qrokut")
                }}
                >
                    QRkodu Okut</button>
            </div>
       
        <h2 style={{color:'white'}} className='bg-black text-white mt-32'>Buradago Admin</h2>

        

    </div>
    
    );

    
}

export default Home;