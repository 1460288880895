import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { disapproveAttendee, getAttendanceList } from "../components/requests";



const Attendees = () => {
    const navigateThere = useNavigate()
    const [events, setevents] = useState([]);
    const [attendants, setattendants] = useState( [ ]);
    const [errorMessage, seterrorMessage] = useState();
    const [toggleLoadAttendees, settoggleLoadAttendees] = useState(true);

    //sample attendants list
//     {   "id":1,
//     "user_id": 18,
//     "first_name": "Rashid",
//     "last_name": "Mukaila",
//     "checked_in": null
// },
// {   "id":2,
//     "user_id": 18,
//     "first_name": "Rashid",
//     "last_name": "Mukaila",
//     "checked_in": 1
// }

    const location = useLocation();
    const eventInfo = location.state || {"event_id":-1033};

    //attendee statuses
   const onayComp = <p className="bg-lime-300 text-xs ">Onaylanmış</p>
   const toOnayComp = <p className="bg-yellow-300 text-xs ">Onaylanmamış</p>
   const redComp = <p className="bg-red-500 text-xs ">Rededilmış</p>

    //reject attendant function
    function disApprove(orderInfo) {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"user_id":orderInfo.user_id,"event_id":orderInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    settoggleLoadAttendees(!toggleLoadAttendees);
                }
            }
        )
    }

    useEffect(() => {
        getAttendanceList({"event_id":eventInfo.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("katılımcı listesi alınamadı")
                } else if (response.status===200) {
                    setattendants(response.data.attendees)
                }
            }
        )
    
    }, [toggleLoadAttendees]);

    
    return(
        <div className="px-4 mt-8">
            <div className="flex justify-end items-center ">
                <button className=" items-center flex
                mt-5 bg-blue-300 p-2 rounded-xl" 
                onClick={() => {
                    navigateThere("/qrokut")
                }}
                >
                    QRkodu Okut</button>
            </div>
            <div className="w-full overflow-y-auto h-96 items-start
             flex justify-center mt-8 border">
                <table className="w-full text-sm rounded-md border-3 bg-gray-300
                mx-4 ">
                    <thead className="w-full space-x-3 text-xs">
                        <tr>
                            <th>Katılımcı ID</th>
                            <th>Katılımcı Ad Soyad</th>
                            <th>Durum</th>
                            <th>Eylemler</th>
                        </tr>

                        {/* <th></th> */}
                    </thead>

                    <tbody className="text-xs">
                        {attendants&&
                            attendants.map((attndnt, index) => (
                                <tr key={index}>
                                    <td className="pb-4">{attndnt.user_id}</td>
                                    <td className="pb-4">{attndnt.first_name +" "+attndnt.last_name}</td>
                                    <td className="pb-4">
                                    {/* render 1 of 3 attendance status conditionally */}
                                        {attndnt.checked_in===1&& onayComp }
                                        {attndnt.checked_in===null&& toOnayComp}
                                        {attndnt.checked_in===-1&& redComp}
                                    </td>

                                    <td className="pb-4">
                                        {/* create redet button if attendant is already 
                                        in event or yet to enter event */}
                                        {attndnt.checked_in===1||attndnt.checked_in===null?
                                         <button
                                         className="bg-red-400  rounded-md text-xs px-2"
                                          onClick={() => { 
                                            disApprove(attndnt)
                                           }}> {/**the id is order id */}
                                            Redet
                                        </button>
                                        :
                                        <></> }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
            </div>
        </div>
    )
}
export default Attendees;