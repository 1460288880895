// import logo from '../logo.svg';

import { useContext, useState, useEffect } from "react";
import {Route,Switch,BrowserRouter, Routes, Outlet, useNavigate} from "react-router-dom";
import './App.css';
// import HeaderPc from './components/header-pc';
import Home from './components/home-page';
// import HeaderMob from './components/header-mob';
// import Footer from './components/footer';
import Layout from "./components/layout";
import NoPage from "./pages/noPage";
import LoginPage from "./pages/login-page";
import MyEvents from "./pages/myevents";
import Attendees from "./pages/attendees";
import ApproveAttendant from "./pages/attendant";
import ReadQrCode from "./pages/read-qr-code";
import LoginForm from "./pages/login";
import UserRejected from "./pages/rejection-success";
import UserApproved from "./pages/approval-success";


function App() {

  const [isLoggedin, setIsLoggedin] = useState('false');


  
//Check if loggedin
// const navigateThere = useNavigate();
// if(loginVal==='true'){
// setIsLoggedin('true')}else{
//   navigateThere('/giris')
// };

  useEffect(()=>{
    let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

  if(loginVal==='true'){
    setIsLoggedin('true')};  

  },[]);

  
  

  return (
    
    <div className="App">

    <BrowserRouter>
   
    
        <Routes>
          
        <Route path="/" element={
          // <LoginProvider value={{isLogin,setIsLogin}}>
        <Layout />
        // </LoginProvider>
        
        }>
        <Route index element={<LoginForm />} />
        {/* <Route index element={<Home />} /> */}
        <Route path="/giris" element={<LoginForm />} />
        <Route path="/rededildi" element={<UserRejected />} />
        <Route path="/onaylandi" element={<UserApproved/>} />
        <Route path="/yetkili-etkinliklerim" element={<MyEvents />} />
        <Route path="/katilimcilar" element={<Attendees />} />
        <Route path="/katilimci-onaylama" element={<ApproveAttendant />} />
        <Route path="/qrokut" element={<ReadQrCode />} />
        

        {/* "iletisim" */}
        {/* <Route path="/footer-element-page3" element={<FooterPage3 />} /> */}

       
        
        <Route path="*" element={<NoPage />} />
      </Route>
      
      </Routes>

      </BrowserRouter>
  
    </div>
    
  );
}

export default App;
