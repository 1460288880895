import React from 'react'
import { useNavigate } from 'react-router-dom'

const UserRejected = () => {

    const navigateThere = useNavigate();
    

  return (
    <div className='w-full flex justify-center h-80 items-center'>
         <div className='bg-red-50 rounded-md w-96 h-52 pt-1'>
            <button
             className='mt-0 text-xs text-blue-400'
             onClick={() => {
                navigateThere("/katilimcilar")
             }}
             >
                katilimci listesi
            </button>
            <h1 className='text-2xl text-red-700 mt-4'>Başarılı Rededilme!</h1>
            <button
            onClick={() => {
                navigateThere("/qrokut")
            }}
            className='mt-9 bg-green-200 rounded-md w-20 h-10 px-2'>
                Devam
            </button>
        </div>
    </div>
  )
}

export default UserRejected;