import axios from 'axios';

//base urls
const DEV_BASE_URL = 'http://127.0.0.1:8000/api';
const PROD_BASE_URL = 'https://api.buradago.com/public/api'


//uncomment the right line bellow based on whether you're deploying on 
//production or developemnt
 const CURRENT_MODE_BASE_URL = PROD_BASE_URL;
const CURRENT_MODE_CSRF_BASE_URL = 'https://api.buradago.com/public';

//  const CURRENT_MODE_BASE_URL = DEV_BASE_URL;
//  const CURRENT_MODE_CSRF_BASE_URL = 'http://127.0.0.1:8000';


 //for wuth
export const AUTH_COOKIE_LINK = CURRENT_MODE_CSRF_BASE_URL+'/sanctum/csrf-cookie';


export const ASSIGNED_EVENTS_URL = CURRENT_MODE_BASE_URL+'/get-assigned-events';  
export const LOGOUT_URL = CURRENT_MODE_BASE_URL+'/logout';  
export const LOGIN_URL = CURRENT_MODE_BASE_URL+'/login'; 
// export const MY_EVENTS_URL = CURRENT_MODE_BASE_URL+'/get-my-events';  
export const APPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/approve-attendee';  
export const DISAPPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/disapprove-attendee';  
export const GET_ATTENDANTS_LIST = CURRENT_MODE_BASE_URL+'/get-attendees';  
export const GET_ATTENDANT_INFO = CURRENT_MODE_BASE_URL + '/get-attendant-info'





// 	baseURL: CURRENT_MODE_BASE_URL,
// });

//login attendee
export const login = (payload)=>{
	axios.get(AUTH_COOKIE_LINK)
	const result = axios.post(LOGIN_URL,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 },
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;

}

//login attendee
export const logout = ()=>{

	const result = axios.get(LOGOUT_URL,
		 {
			headers: { 'Content-Type': 'application/json',
		 'Accept':'application/json',
		 // 'Access-Control-Allow-Credentials':true
			},
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;

}


//approve attendee
export const approveAttendee = (payload)=>{

	const result = axios.get(APPROVE_ATTENDEE_URL,payload,
		
		{	
			headers: { 'Content-Type': 'application/json',
			'Accept':'application/json',
			// 'Access-Control-Allow-Credentials':true
			},
			
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;

}

//disapprove attendee
export const disapproveAttendee = (payload)=>{

	const result = axios.post(DISAPPROVE_ATTENDEE_URL,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;

}

//axios.get all event
export const getMyEvents = ()=>{

	const result = axios.get(ASSIGNED_EVENTS_URL,
		
		{
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},

			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;
}

//axios.get all event
export const getAttendanceList = (payload)=>{

	const result = axios.post(GET_ATTENDANTS_LIST,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},

			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;

}

//get an attendants info
export const getAttendantInfo = (payload)=>{

	const result = axios.post(GET_ATTENDANT_INFO,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

		return result;


}



