import { useState } from "react";
import {QrScanner} from '@yudiel/react-qr-scanner';
import { useNavigate } from "react-router-dom";



const ReadQrCode = () => {
    const [events, setevents] = useState([]);
    const [attendant, setattendant] = useState();
    const [qrcodeData, setqrcodeData] = useState("")
    const [showScanner, setshowScanner] = useState(true)

    const navigateThere = useNavigate();

    function onQrDecode(result) {
        setshowScanner(false);
        setqrcodeData(result);
        navigateThere("/katilimci-onaylama", {"state":{"order_id" : result}})
        
    }

    return(
        <div className="px-4">
            <div className="w-full overflow-y-auto h-96 items-start flex justify-center mt-16 border">
            
            {qrcodeData&&
            <p className="text-black">{ 
                qrcodeData.toString()}
                
                </p>
            }
            {showScanner&&<QrScanner
                onDecode={
                    // (result) => console.log(result)
                    (result) => {
                        onQrDecode(result);
                    }
                }
                onError={
                    // (error) => ()
                    (first) => {  }
                }
             />}
            </div>
        </div>
    )
}
export default ReadQrCode;