// import React from "react"
import { Outlet } from "react-router-dom";




const Layout = () =>{
// const {isLogin} = useContext(isLoginContext); 


 return(   
<div className="App">
{/* <header className="App-header"> */}
{/* {isLogin?<HeaderLoggedIn/>:<Header />} */}
{/* </header> */}
<Outlet />
</div>
 );
 
}

export default Layout;