import React from 'react'
import { GeneralNavigateThere } from './helpers';
import { useNavigate } from 'react-router-dom';

const UserApproved = () => {

    const navigateThere = useNavigate()
  return (
    <div className='w-full flex justify-center h-80 items-center'>
        <div className='bg-lime-50 rounded-md w-96 h-52 pt-1'>
            <button
             className='mt-0 text-xs text-blue-400'
             onClick={() => {
                navigateThere("/katilimcilar")
             }}
             >
                katilimci listesi
            </button>
            <h1 className='text-2xl text-lime-400 mt-4'>Başarılı Onaylama!</h1>
            <button 
            className='mt-9 bg-green-200 rounded-md w-20 h-10 px-2 '
            onClick={() => {
                navigateThere("/qrokut")
            }}
            >
                Devam
            </button>
        </div>

    </div>
  )
}

export default UserApproved;