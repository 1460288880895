import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMyEvents } from "../components/requests";

const sampleEvents = [
    {
        "id": 1033,
        "fenomen_id": 1,
        "etkinlik_adi": "Ocay Ile kahve border",
        "etkinlik_mekan": "Gungorein",
        "satis_baslangic_tarihi": "2023-08-31T21:00:00.000Z",
        "satis_bitis_tarihi": "2023-09-05T21:00:00.000Z",
        "il": "İstanbul",
        "ilce": "güngörein",
        "etkinlik_sekil": "artirmali",
        "etkinlik_tarihi": "2023-09-08T21:00:00.000Z",
        "saat": "4:00",
        "katilim_fiyati": "200",
        "baslangic_fiyati": 100,
        "minimum_art_tutari": 50,
        "kategori_id": 0,
        "katilim_sayisi": 20,
        "s_bisaat": "",
        "s_basaat": "",
        "event_pic_url": null,
        "created_at": "2023-09-24 06:53:27",
        "updated_at": "2023-12-28 08:49:05"
    }
]


const MyEvents = () => {
    const [events, setevents] = useState([]);

    const navigateThere = useNavigate();

    useEffect(() => {
      getMyEvents().then((response) => {
        setevents(response.data.events);

      })}
    
     , []);
    

    
    return(
        <div className="px-4  xl:flex xl:justify-center">
            <div className="w-full xl:w-[50%]">

            <h2 className="mt-6 mb-10 font-bold text-sm">
                YETKİLİ ETKİNLİKLERİM
            </h2>
            
               <div className="flex justify-end items-center ">
                <button className=" items-center flex
                mt-2 bg-blue-300 px-2 text-sm rounded-xl" 
                onClick={() => {
                    navigateThere("/qrokut")
                }}
                >
                    QRkodu Okut</button>
            </div>
            <div className="w-full overflow-y-auto  items-start flex
             justify-center mt-3 border h-[70%] overflow-x-auto rounded-t-md
              text-xs">
               
                <table className="w-full">
                    <thead>
                        <tr className="w-full rounded-full bg-blue-50 ">
                            <th>Etkinlik ID</th>
                            <th>Etkinlik Adı</th>
                            <th>Eylemler</th>
                            {/* <th></th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {events&&
                            events.map((evnt,index) => ( 
                                <tr key={index} className="border-t">
                                    <td className="py-2">{evnt.id}</td>
                                    <td className="py-2 w-[30%]">{evnt.etkinlik_adi}</td>
                                    <td className="py-2">
                                        <button
                                         className="bg-blue-400 px-1 rounded-md"
                                         onClick={() => { 
                                            navigateThere("/katilimcilar",{"state":{"event_id":evnt.id}});
                                            // console.log(evnt.id);
                                          }}
                                         >
                                            Katılımcılar
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>

                </table>
            </div>
        </div>
            </div>
    )
}
export default MyEvents;