import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { approveAttendee, disapproveAttendee, getAttendantInfo } from "../components/requests";



const ApproveAttendant = () => {
    const [events, setevents] = useState([]);
    const [attendant, setattendant] = useState({});
    const [errorMessage, seterrorMessage] = useState('');
    const location = useLocation();
    const orderInfo = location?.state || -1;

    const navigateThere = useNavigate();

    //error component
    const errorComp = <div className="bg-red-400 w-fit
    rounded-sm px-1 h-fit">{errorMessage}</div>

    //approve attebdant function
    function approve() {
        //clear previus error message if any
        seterrorMessage('')
        approveAttendee({"order_id":orderInfo.order_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Onaylama! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    navigateThere("/onaylandi")                    
                }
            }
        )
    }

    //reject attendant function
    function disApprove() {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"order_id":orderInfo.order_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    navigateThere("/rededildi")                    
                }
            }
        )
    }

    useEffect(() => {
        // 
        getAttendantInfo({"order_id":orderInfo?.order_id}).then(
            (response) => {
                if (!response.status) {
                    
                }else if (response.status===200 && response.data.order_info.length>0) {
                    setattendant(response.data.order_info[0])
                }
            }
        )
    }, []);


    return(
        <div className="px-4 w-full overflow-y-auto h-96
        flex justify-center mt-16 border">
            <div className=" space-y-5 text-start mx-auto mt-12 w-72">
            {errorComp&&<div className="w-full flex justify-center">
                 {errorComp}
            </div>}
               <p className="">Ad Soyad:
               {attendant.first_name&&

                <span className="ml-3">{attendant?.first_name +" "+attendant?.last_name}</span>
                }
                </p>
               <p>Durum:
                
                 <span className="ml-7 text-lime-400">{attendant?.checked_in===1?"Onaylanmış":""}</span>
                 <span className="ml-7 text-red-500">{attendant?.checked_in===-1?"Rededildi!":""}</span>
               </p>

            <div className="flex justify-center w-full">
                
           { attendant?.checked_in===0&&
           <button className='mt-2 bg-green-300 rounded-md w-32'
               onClick={() => { approve() }}
               >
                    Onayla
            </button>
           }

             {attendant?.checked_in!==-1 &&
                <button className=' bg-red-300 rounded-md w-20 h-fit
             p-0 justify-center mt-2 ml-auto'
             onClick={() => {disApprove()}}
               >
                Redet
               </button>
            }
            </div>

             <div className="flex justify-center">

             </div>

            </div>
        </div>
    )
}
export default ApproveAttendant;